import { call, put, takeEvery } from "redux-saga/effects";

import {
  GET_USERS,
  GET_USER_PROFILE,
  GET_USER_REQUEST_INFO,
  DELETE_USER,
  GET_USER_RESUME,
} from "./actionTypes";

import {
  getUsersSuccess,
  getUsersFail,
  getUserProfileSuccess,
  getUserProfileFail,
  getUserRequestInfoFail,
  getUserRequestInfoSuccess,
  getUserResumeSuccess,
  getUserResumeFail,
  deleteUserSuccess,
  deleteUserFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getUsers,
  getUserProfile,
  getUserRequestInfo,
  getUserResume,
  deleteUser,
} from "../../helpers/backend_helper";

function* fetchUsers() {
  try {
    const response = yield call(getUsers);
    yield put(getUsersSuccess(response.payload.userInfo));
  } catch (error) {
    yield put(getUsersFail(error));
  }
}

function* fetchUserProfile({ userId }) {
  try {
    const response = yield call(getUserProfile, userId);
    yield put(getUserProfileSuccess(response.payload.userInfo[0]));
  } catch (error) {
    yield put(getUserProfileFail(error));
  }
}

function* onGetUserResume({ payload: user }) {
  try {
    const response = yield call(getUserResume, user);
    yield put(getUserResumeSuccess(response.payload.userResumeInfo));
  } catch (error) {
    yield put(getUserResumeFail(error));
  }
}

function* onDeleteUser({ payload: user }) {
  try {
    const response = yield call(deleteUser, user);
    yield put(deleteUserSuccess(response));
  } catch (error) {
    yield put(deleteUserFail(error));
  }
}

function* onGetUserRequestInfo({ payload: user }) {
  try {
    const response = yield call(getUserRequestInfo, user);
    yield put(getUserRequestInfoSuccess(response.payload.userRequestInfo));
  } catch (error) {
    yield put(getUserRequestInfoFail(error));
  }
}

function* contactsSaga() {
  yield takeEvery(GET_USERS, fetchUsers);
  yield takeEvery(GET_USER_PROFILE, fetchUserProfile);
  yield takeEvery(GET_USER_REQUEST_INFO, onGetUserRequestInfo);
  yield takeEvery(GET_USER_RESUME, onGetUserResume);
  yield takeEvery(DELETE_USER, onDeleteUser);
}

export default contactsSaga;
