import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col } from "reactstrap";
import { useSelector } from "react-redux";
//import images
import wechat from "../../../assets/images/companies/img-1.png";

const DetailsSection = () => {
  const { job } = useSelector((state) => ({
    job: state.JobReducer.jobDetails,
  }));

  return (
    <React.Fragment>
      <Col xl={9}>
        <Card>
          <CardBody className="border-bottom">
            <div className="d-flex">
              <img src={wechat} alt="" height="50" />
              <div className="flex-grow-1 ms-3">
                <h5 className="fw-semibold">{job?.title}</h5>
                <ul className="list-unstyled hstack gap-2 mb-0">
                  <li>
                    <i className="bx bx-building-house"></i>{" "}
                    <span className="text-muted">
                      {job?.business_info?.full_name}
                    </span>
                  </li>
                  <li>
                    <i className="bx bx-map"></i>{" "}
                    <span className="text-muted">
                      {job?.business_info?.business_address}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </CardBody>
          <CardBody>
            <h5 className="fw-semibold mb-3">Description</h5>
            <p className="text-muted">{job?.description}</p>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default DetailsSection;
