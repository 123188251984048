import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col } from "reactstrap";

const Sidebar = ({ experience }) => {
  return (
    <React.Fragment>
      <Col lg={6}>
        <Card>
          <CardBody>
            <h5 className="mb-3">Experience</h5>
            <ul className="verti-timeline list-unstyled">
              {experience.map((item) => (
                <li className="event-list" key={item.id}>
                  <div className="event-timeline-dot">
                    <i className="bx bx-right-arrow-circle"></i>
                  </div>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <div>
                        <h6 className="font-size-14 mb-1">
                          {item.company_name}
                        </h6>
                        <p className="text-muted">{item.job_title}</p>
                        <p className="text-muted mb-0">
                          {`${new Date(
                            item.start_date
                          ).toDateString()} to ${new Date(
                            item.end_date
                          ).toDateString()}`}
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default Sidebar;
