import {
  ADD_CATEGORIES_SUCCESS,
  ADD_CATEGORIES_FAIL,
  GET_SUB_CATEGORIES_SUCCESS,
  GET_SUB_CATEGORIES_FAIL,
  UPDATE_CATEGORIES_SUCCESS,
  UPDATE_CATEGORIES_FAIL,
  DELETE_CATEGORIES_FAIL,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,
  DELETE_SUBCATEGORY_FAIL,
  GET_SUB_CATEGORIES_DETAILS_SUCCESS,
  GET_SUB_CATEGORIES_DETAILS_FAIL,
  GET_SUB_CATEGORIES_QNS_SUCCESS,
  GET_SUB_CATEGORIES_QNS_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  events: [],
  categories: [],
  subCategories: [],
  subCategoryQns: [],
  subCategory: null,
  error: {},
};

const Calendar = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload,
      };

    case GET_CATEGORIES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_SUB_CATEGORIES_SUCCESS:
      return {
        ...state,
        subCategories: action.payload,
      };

    case GET_SUB_CATEGORIES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_SUB_CATEGORIES_DETAILS_SUCCESS:
      return {
        ...state,
        subCategory: action.payload,
      };

    case GET_SUB_CATEGORIES_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_SUB_CATEGORIES_QNS_SUCCESS:
      return {
        ...state,
        subCategoryQns: action.payload,
      };

    case GET_SUB_CATEGORIES_QNS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case ADD_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: [...state.categories, action.payload.payload.category],
      };

    case ADD_CATEGORIES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_CATEGORIES_SUCCESS:
      return {
        ...state,
        events: state.events.map((event) =>
          event.id.toString() === action.payload.id.toString()
            ? { event, ...action.payload }
            : event
        ),
      };

    case UPDATE_CATEGORIES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_CATEGORIES_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_SUBCATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default Calendar;
