import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Card, CardBody, Col } from "reactstrap";
import { getAwsFile } from "helpers/aws";

const Overview = () => {
  const { job } = useSelector((state) => ({
    job: state.JobReducer.jobDetails,
  }));

  return (
    <React.Fragment>
      <Col xl={3}>
        <Card>
          <CardBody>
            <h5 className="fw-semibold">Overview</h5>
            <div className="table-responsive">
              <table className="table">
                <tbody>
                  <tr>
                    <th scope="col">Job Title</th>
                    <td scope="col">{job?.title}</td>
                  </tr>
                  <tr>
                    <th scope="row">Experience:</th>
                    <td>{job?.min_experience}</td>
                  </tr>
                  <tr>
                    <th scope="row">Vacancy</th>
                    <td>{job?.vacancy}</td>
                  </tr>
                  <tr>
                    <th scope="row">Job Type</th>
                    <td>
                      <span className="badge badge-soft-success">
                        {job?.job_type}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Status</th>
                    <td>
                      <span
                        className={`badge badge-soft-${
                          job?.status > 0
                            ? job.status > 1
                              ? "info"
                              : "success"
                            : "danger"
                        }`}
                      >
                        {job?.status > 0
                          ? job.status > 1
                            ? "Draft"
                            : "Active"
                          : "Deleted"}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Qualification</th>
                    <td>{job?.min_qualification}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <div className="text-center">
              <img
                src={getAwsFile(job?.business_info?.business_profile)}
                alt="business_profile"
                height="50"
                className="mx-auto d-block"
              />
              <h5 className="mt-3 mb-1">{job?.business_info?.full_name}</h5>
            </div>

            <ul className="list-unstyled mt-4">
              <li>
                <div className="d-flex">
                  <i className="bx bx-phone text-primary fs-4"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Phone</h6>
                    <p className="text-muted fs-14 mb-0">
                      {job?.business_info?.business_phone}
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-3">
                <div className="d-flex">
                  <i className="bx bx-mail-send text-primary fs-4"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Email</h6>
                    <p className="text-muted fs-14 mb-0">
                      {job?.business_info?.business_email}
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-3">
                <div className="d-flex">
                  <i className="bx bx-globe text-primary fs-4"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Website</h6>
                    <p className="text-muted fs-14 text-break mb-0">
                      {job?.company_website}
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-3">
                <div className="d-flex">
                  <i className="bx bx-map text-primary fs-4"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Location</h6>
                    <p className="text-muted fs-14 mb-0">
                      {job?.business_info?.business_address}
                    </p>
                  </div>
                </div>
              </li>
            </ul>
            <div className="mt-4">
              <Link
                to={`/company/${job?.business_info?.id}`}
                className="btn btn-soft-primary btn-hover w-100 rounded"
              >
                <i className="mdi mdi-eye"></i> View Profile
              </Link>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default Overview;
