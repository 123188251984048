import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Container,
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";
import {
  addNewCategories as onAddNewCategory,
  getCategories as onGetCategories,
} from "store/category/actions";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import List from "./List";
import { useDispatch, useSelector } from "react-redux";

const CandidateList = (props) => {
  document.title = "Services | Securlist - Dashboard";

  const dispatch = useDispatch();

  const [event, setEvent] = useState({});
  const categoryValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (event && event.title) || "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Category Name"),
    }),
    onSubmit: (values) => {
      const newEvent = {
        name: values["title"],
      };
      // save new event
      dispatch(onAddNewCategory(newEvent));
      toggleCategory();
    },
  });
  const [category, setCategories] = useState([]);
  const [modalcategory, setModalcategory] = useState(false);

  const toggleCategory = () => {
    setModalcategory(!modalcategory);
  };

  useEffect(() => {
    dispatch(onGetCategories());
  }, [dispatch]);

  const { categories } = useSelector((state) => state.category);

  useEffect(() => {
    setCategories(categories);
  }, [categories]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Services" breadcrumbItem="Services List" />
          <Row>
            <Col lg={3}>
              <div className="d-grid mb-3">
                <Button
                  color="primary"
                  className="font-16 btn-block"
                  onClick={toggleCategory}
                >
                  <i className="mdi mdi-plus-circle-outline me-1" />
                  Add Category
                </Button>
              </div>
            </Col>
          </Row>

          <List categories={category} />
        </Container>
        <Modal
          isOpen={modalcategory}
          toggle={toggleCategory}
          className={props.className}
          centered
        >
          <ModalHeader toggle={toggleCategory} tag="h5">
            Add Category
          </ModalHeader>
          <ModalBody className="p-4">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                categoryValidation.handleSubmit();
                return false;
              }}
            >
              <Row>
                <Col className="col-12">
                  <div className="mb-3">
                    <Label className="form-label">Category Name</Label>
                    <Input
                      name="title"
                      type="text"
                      // value={event ? event.title : ""}
                      onChange={categoryValidation.handleChange}
                      onBlur={categoryValidation.handleBlur}
                      value={categoryValidation.values.title || ""}
                      invalid={
                        categoryValidation.touched.title &&
                        categoryValidation.errors.title
                          ? true
                          : false
                      }
                    />
                    {categoryValidation.touched.title &&
                    categoryValidation.errors.title ? (
                      <FormFeedback type="invalid">
                        {categoryValidation.errors.title}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>

              <Row className="mt-2">
                <Col className="col-6">
                  <button
                    type="button"
                    className="btn btn-light me-1"
                    onClick={toggleCategory}
                  >
                    Close
                  </button>
                </Col>
                <Col className="col-6 text-end">
                  <button
                    type="submit"
                    className="btn btn-success"
                    id="btn-save-event"
                  >
                    Save
                  </button>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default CandidateList;
