import React, { useEffect, useMemo } from "react";
import { Card, CardBody, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import TableContainer from "components/Common/TableContainer";
import { getCompanyLeads as onGetCompanyLeads } from "store/actions";
import { LeadName, LeadService } from "pages/JobPages/ApplyJobs/ApplyJobsCol";

function BusinessLeads({ companyId }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onGetCompanyLeads(companyId));
  }, [dispatch]);

  const { leads } = useSelector((state) => state.projects);

  const columns = useMemo(
    () => [
      {
        Header: "User Names.",
        accessor: "request",
        disableFilters: true,
        Cell: (cellProps) => {
          return <LeadName {...cellProps} />;
        },
      },
      {
        Header: "Service Requested",
        accessor: "service",
        disableFilters: true,
        Cell: (cellProps) => {
          return <p>{cellProps.row.original?.request?.business_type?.name}</p>;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        disableFilters: true,
        Cell: (cellProps) => {
          return <LeadService {...cellProps} />;
        },
      },
      ,
    ],
    []
  );

  return (
    <Col xl={8}>
      <h5>Company Leads</h5>
      <Card>
        <CardBody className="border-bottom">
          <TableContainer
            columns={columns}
            data={leads || []}
            isGlobalFilter={false}
            isAddUserList={false}
            customPageSize={4}
          />
        </CardBody>
      </Card>
    </Col>
  );
}

export default BusinessLeads;
