import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import { getSubCategory as onGetSubCategories } from "store/actions";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import JobData from "./JobData";

const JobGrid = () => {
  document.title = "Sub categories | Securlist - Dashboard";

  const dispatch = useDispatch();

  const data = useSelector((state) => state.category.subCategories);

  const [subCategories, setSubCategories] = useState([]);

  useEffect(() => {
    dispatch(onGetSubCategories());
  }, [dispatch]);

  useEffect(() => {
    setSubCategories(data);
  }, [data]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Sub categories" breadcrumbItem="List" />
          <JobData subCategories={subCategories} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default JobGrid;
