import React from "react";
import { Container, Row } from "reactstrap";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import { getJobDetails as onGetJobDetails } from "store/actions";
import Overview from "./Overview";
import DetailsSection from "./DetailsSection";

const JobDetails = () => {
  document.title = "Job Details | Securlist - Dashboard";
  const dispatch = useDispatch();
  const { id } = useParams();

  React.useEffect(() => {
    dispatch(onGetJobDetails(id));
  }, [dispatch]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Jobs" breadcrumbItem="Job Details" />
          <Row>
            <Overview />
            <DetailsSection />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default JobDetails;
