import React from "react";
import { Badge } from "reactstrap";

const JobTitle = (cell) => {
  return cell.value ? cell.value : "";
};

const Name = (cell) => {
  return cell.value.user_name ? cell.value.user_name : "";
};

const CompanyName = (cell) => {
  return cell.value === 1 ? "Male" : "Female";
};

const QuestionType = (cell) => {
  return cell.value === 1 ? "Common" : "Business Specific";
};

const Category = (cell) => {
  return cell.value.name ? cell.value.name : "";
};

const Type = (cell) => {
  switch (cell.value) {
    case true:
      return <Badge className="badge-soft-success">Yes</Badge>;
    case false:
      return <Badge className="badge-soft-warning">No</Badge>;
  }
};

const ApplyDate = (cell) => {
  return cell.value ? cell.value : "";
};

const Status = (cell) => {
  switch (cell.value) {
    case "Active":
      return <Badge className="bg-success">Active</Badge>;
    case "New":
      return <Badge className="bg-info">New</Badge>;
    case "Close":
      return <Badge className="bg-danger">Close</Badge>;
  }
};

const LeadName = (cell) => {
  return cell.value?.user?.user_name ? cell.value?.user?.user_name : "";
};

const LeadService = (cell) => {
  return cell.value?.business_type?.name ? cell.value?.business_type?.name : "";
};

export {
  JobTitle,
  CompanyName,
  Type,
  ApplyDate,
  Status,
  Name,
  Category,
  QuestionType,
  LeadName,
  LeadService,
};
