import {
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  GET_USER_REQUEST_INFO_SUCCESS,
  GET_USER_REQUEST_INFO_FAIL,
  GET_USER_RESUME_SUCCESS,
  GET_USER_RESUME_FAIL,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAIL,
} from "./actionTypes";
const INIT_STATE = {
  users: [],
  userProfile: {},
  userRequestsInfo: [],
  userResumeInfo: [],
  error: {},
};

const contacts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
      };

    case GET_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_USER_REQUEST_INFO_SUCCESS:
      return {
        ...state,
        userRequestInfo: action.payload,
      };

    case GET_USER_REQUEST_INFO_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: action.payload,
      };

    case GET_USER_RESUME_SUCCESS:
      return {
        ...state,
        userResumeInfo: action.payload,
      };

    case GET_USER_RESUME_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.filter(
          (user) => user.id.toString() !== action.payload.toString()
        ),
      };

    case DELETE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_USER_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default contacts;
