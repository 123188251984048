import {
  GET_USER_PROFILE,
  GET_USER_PROFILE_FAIL,
  GET_USER_PROFILE_SUCCESS,
  GET_USERS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  GET_USER_REQUEST_INFO,
  GET_USER_REQUEST_INFO_SUCCESS,
  GET_USER_REQUEST_INFO_FAIL,
  GET_USER_RESUME,
  GET_USER_RESUME_SUCCESS,
  GET_USER_RESUME_FAIL,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
} from "./actionTypes";
export const getUsers = () => ({
  type: GET_USERS,
});

export const getUsersSuccess = (users) => ({
  type: GET_USERS_SUCCESS,
  payload: users,
});

export const getUserRequestInfo = (user) => ({
  type: GET_USER_REQUEST_INFO,
  payload: user,
});

export const getUserRequestInfoSuccess = (user) => ({
  type: GET_USER_REQUEST_INFO_SUCCESS,
  payload: user,
});

export const getUserRequestInfoFail = (error) => ({
  type: GET_USER_REQUEST_INFO_FAIL,
  payload: error,
});

export const getUsersFail = (error) => ({
  type: GET_USERS_FAIL,
  payload: error,
});

export const getUserProfile = (userId) => ({
  type: GET_USER_PROFILE,
  userId,
});

export const getUserProfileSuccess = (userProfile) => ({
  type: GET_USER_PROFILE_SUCCESS,
  payload: userProfile,
});

export const getUserProfileFail = (error) => ({
  type: GET_USER_PROFILE_FAIL,
  payload: error,
});

export const getUserResume = (user) => ({
  type: GET_USER_RESUME,
  payload: user,
});

export const getUserResumeSuccess = (user) => ({
  type: GET_USER_RESUME_SUCCESS,
  payload: user,
});

export const getUserResumeFail = (error) => ({
  type: GET_USER_RESUME_FAIL,
  payload: error,
});

export const deleteUser = (user) => ({
  type: DELETE_USER,
  payload: user,
});

export const deleteUserSuccess = (user) => ({
  type: DELETE_USER_SUCCESS,
  payload: user,
});

export const deleteUserFail = (error) => ({
  type: DELETE_USER_FAIL,
  payload: error,
});
