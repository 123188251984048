import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Container, Card, CardBody, Col, Row, Badge, Table } from "reactstrap";
import {
  getProjectDetail as onGetProjectDetail,
  updateProject as onUpdateProject,
} from "store/actions";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import BusinessServices from "./BusinessServices";
import BusinessLeads from "./BusinessLeads";
import { getAwsFile } from "helpers/aws";

const ProjectStatus = ({ status }) => {
  switch (status) {
    case 0:
      return <Badge className="bg-warning"> No </Badge>;

    case 1:
      return <Badge className="bg-success"> Yes </Badge>;

    default:
      return <Badge className="bg-success"> Yes </Badge>;
  }
};

const BlogDetails = () => {
  //meta title
  document.title = "Company Details | Securlist - Dashboard";
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onGetProjectDetail(id));
  }, [dispatch]);

  const { project } = useSelector((state) => ({
    project: state.projects.projectDetail,
  }));

  const handleUpdateCompany = (is_kyc_done) => {
    let newData = { ...project, is_kyc_done: parseInt(is_kyc_done) };
    delete newData["password"];
    dispatch(onUpdateProject(newData));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Company" breadcrumbItem="Company Details" />
          <Card>
            <CardBody>
              <div className="d-flex">
                <img
                  src={getAwsFile(project?.business_profile)}
                  alt=""
                  className="avatar-sm me-4"
                />

                <div className="flex-grow-1 overflow-hidden">
                  <h5 className="text-truncate font-size-15">
                    {project?.full_name}
                  </h5>
                  <p className="text-muted">
                    {project?._business_type?.category}
                  </p>
                </div>
              </div>

              <h5 className="font-size-15 mt-4">Company Details :</h5>

              {/* {project?.is_kyc_done == "1" ? ( */}
              <div className="table-responsive">
                <Table className="table mb-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Insurance</th>
                      <th>Licence</th>
                      <th>Website</th>
                    </tr>
                  </thead>
                  <tbody>
                    {project.business_kyc && (
                      <tr>
                        <th scope="row">1</th>
                        <td>
                          <a
                            href={getAwsFile(
                              project?.business_kyc[0].insurance_proof
                            )}
                            download="Insurance"
                            className="btn btn-primary"
                          >
                            Download{" "}
                            <i className="bx bx-download align-baseline ms-1"></i>
                          </a>
                        </td>
                        <td>
                          <a
                            href={getAwsFile(
                              project?.business_kyc[0].licence_proof
                            )}
                            download="Licence"
                            className="btn btn-primary"
                          >
                            Download{" "}
                            <i className="bx bx-download align-baseline ms-1"></i>
                          </a>
                        </td>
                        <td>
                          <a
                            className="btn btn-soft-primary w-100"
                            href={project?.business_kyc[0]?.website}
                            target="_blank"
                          >
                            Link
                          </a>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              {/* ) : (
                <p className="text-muted">No KYC documents provides.</p>
              )} */}

              <div className="text-muted mt-4">
                <p>
                  <i className="mdi mdi-email text-primary me-1" />{" "}
                  {project?.business_email}
                </p>
                <p>
                  <i className="mdi mdi-cellphone text-primary me-1" />{" "}
                  {project?.business_phone}
                </p>
                <p>
                  <i className="mdi mdi-google-maps text-primary me-1" />{" "}
                  {project?.business_address}
                </p>
              </div>

              <Row className="task-dates">
                <Col sm="4" xs="6">
                  <div className="mt-4">
                    <h5 className="font-size-14">
                      <i className="bx bx bx-shield me-1 text-success" /> Is
                      verified
                    </h5>
                    <ProjectStatus status={project?.is_kyc_done} />
                  </div>
                </Col>

                <Col sm="4" xs="6">
                  <div className="mt-4">
                    {project?.is_kyc_done == 0 ? (
                      <button
                        className="btn btn-success w-100"
                        onClick={() => handleUpdateCompany(1)}
                      >
                        Verify
                      </button>
                    ) : (
                      <button
                        className="btn btn-danger w-100"
                        onClick={() => handleUpdateCompany(0)}
                      >
                        Deactivate
                      </button>
                    )}
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Row>
            <BusinessServices companyId={id} />
            <BusinessLeads companyId={id} />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default BlogDetails;
