import { call, put, takeEvery } from "redux-saga/effects";

import {
  GET_JOB_LIST,
  ADD_NEW_JOB_LIST,
  GET_JOB_DETAILS,
  DELETE_JOB_LIST,
  GET_APPLY_JOB,
  DELETE_APPLY_JOB,
  GET_APPLY_JOB_DETAILS,
} from "./actionTypes";

import {
  getJobListSuccess,
  getJobListFail,
  addJobListSuccess,
  addJobListFail,
  getJobDetailsSuccess,
  getJobDetailsFail,
  deleteJobListSuccess,
  deleteJobListFail,
  getApplyJobSuccess,
  getApplyJobFail,
  deleteApplyJobSuccess,
  deleteApplyJobFail,
  getApplyJobDetailsSuccess,
  getApplyJobDetailsFail,
} from "./actions";

import {
  getJobList,
  addNewJobList,
  getJobDetails,
  deleteJobList,
  getApplyJob,
  deleteApplyJob,
  getApplyJobDetails,
} from "helpers/backend_helper";

function* fetchJobsList() {
  try {
    const response = yield call(getJobList);
    yield put(getJobListSuccess(response.payload.allJobList));
  } catch (error) {
    yield put(getJobListFail(error));
  }
}

function* onAddNewJobList({ payload: data }) {
  try {
    const response = yield call(addNewJobList, data);
    yield put(addJobListSuccess(response));
  } catch (error) {
    yield put(addJobListFail(error));
  }
}

function* onGetJobDetails({ payload }) {
  try {
    const response = yield call(getJobDetails, payload);
    yield put(getJobDetailsSuccess(response.payload.allJobList[0]));
  } catch (error) {
    yield put(getJobDetailsFail(error));
  }
}

function* onDeleteJobList({ payload: data }) {
  try {
    const response = yield call(deleteJobList, data);
    yield put(deleteJobListSuccess(response));
  } catch (error) {
    yield put(deleteJobListFail(error));
  }
}

function* OnGetApplyJob() {
  try {
    const response = yield call(getApplyJob);
    yield put(getApplyJobSuccess(response.payload.getprofileList));
  } catch (error) {
    yield put(getApplyJobFail(error));
  }
}

function* OnGetApplyJobDetails({ payload: data }) {
  try {
    const response = yield call(getApplyJobDetails, data);
    yield put(getApplyJobDetailsSuccess(response.payload.getprofileList[0]));
  } catch (error) {
    yield put(getApplyJobDetailsFail(error));
  }
}

function* OnDeleteApplyJob({ payload: data }) {
  try {
    const response = yield call(deleteApplyJob, data);
    yield put(deleteApplyJobSuccess(response));
  } catch (error) {
    yield put(deleteApplyJobFail(error));
  }
}

function* jobsSaga() {
  yield takeEvery(GET_JOB_LIST, fetchJobsList);
  yield takeEvery(ADD_NEW_JOB_LIST, onAddNewJobList);
  yield takeEvery(GET_JOB_DETAILS, onGetJobDetails);
  yield takeEvery(DELETE_JOB_LIST, onDeleteJobList);
  yield takeEvery(GET_APPLY_JOB, OnGetApplyJob);
  yield takeEvery(DELETE_APPLY_JOB, OnDeleteApplyJob);
  yield takeEvery(GET_APPLY_JOB_DETAILS, OnGetApplyJobDetails);
}

export default jobsSaga;
