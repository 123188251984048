import {
  GET_COMPANIES,
  GET_COMPANIES_FAIL,
  GET_COMPANIES_SUCCESS,
  GET_COMPANY_DETAIL,
  ADD_NEW_PROJECT,
  ADD_PROJECT_SUCCESS,
  ADD_PROJECT_FAIL,
  UPDATE_PROJECT,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAIL,
  DELETE_PROJECT,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAIL,
  GET_COMPANY_DETAIL_FAIL,
  GET_COMPANY_DETAIL_SUCCESS,
  GET_COMPANY_SERVICES,
  GET_COMPANY_SERVICES_FAIL,
  GET_COMPANY_SERVICES_SUCCESS,
  GET_COMPANY_LEADS,
  GET_COMPANY_LEADS_FAIL,
  GET_COMPANY_LEADS_SUCCESS,
} from "./actionTypes";

export const getCompanyServices = (companyId) => ({
  type: GET_COMPANY_SERVICES,
  payload: companyId,
});

export const getCompanyServicesSuccess = (payload) => ({
  type: GET_COMPANY_SERVICES_SUCCESS,
  payload,
});

export const getCompanyServicesFail = (payload) => ({
  type: GET_COMPANY_SERVICES_FAIL,
  payload,
});

export const getCompanyLeads = (companyId) => ({
  type: GET_COMPANY_LEADS,
  payload: companyId,
});

export const getCompanyLeadsSuccess = (payload) => ({
  type: GET_COMPANY_LEADS_SUCCESS,
  payload,
});

export const getCompanyLeadsFail = (payload) => ({
  type: GET_COMPANY_LEADS_FAIL,
  payload,
});

export const getProjects = () => ({
  type: GET_COMPANIES,
});

export const getProjectsSuccess = (projects) => ({
  type: GET_COMPANIES_SUCCESS,
  payload: projects,
});

export const addNewProject = (project) => ({
  type: ADD_NEW_PROJECT,
  payload: project,
});

export const addProjectSuccess = (project) => ({
  type: ADD_PROJECT_SUCCESS,
  payload: project,
});

export const addProjectFail = (error) => ({
  type: ADD_PROJECT_FAIL,
  payload: error,
});

export const updateProject = (project) => ({
  type: UPDATE_PROJECT,
  payload: project,
});

export const updateProjectSuccess = (project) => ({
  type: UPDATE_PROJECT_SUCCESS,
  payload: project,
});

export const updateProjectFail = (error) => ({
  type: UPDATE_PROJECT_FAIL,
  payload: error,
});

export const deleteProject = (project) => ({
  type: DELETE_PROJECT,
  payload: project,
});

export const deleteProjectSuccess = (project) => ({
  type: DELETE_PROJECT_SUCCESS,
  payload: project,
});

export const deleteProjectFail = (error) => ({
  type: DELETE_PROJECT_FAIL,
  payload: error,
});

export const getProjectsFail = (error) => ({
  type: GET_COMPANIES_FAIL,
  payload: error,
});

export const getProjectDetail = (projectId) => ({
  type: GET_COMPANY_DETAIL,
  projectId,
});

export const getProjectDetailSuccess = (projectDetails) => ({
  type: GET_COMPANY_DETAIL_SUCCESS,
  payload: projectDetails,
});

export const getProjectDetailFail = (error) => ({
  type: GET_COMPANY_DETAIL_FAIL,
  payload: error,
});
