import {
  GET_QUESTIONS_FAIL,
  GET_QUESTIONS_SUCCESS,
  GET_QUESTION_DETAIL_SUCCESS,
  GET_QUESTION_DETAIL_FAIL,
  GET_QUESTION_ANSWERS_SUCCESS,
  GET_QUESTION_ANSWERS_FAIL,
  DELETE_QUESTION_FAIL,
  DELETE_ANSWER_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  invoices: [],
  invoiceDetail: {},
  questionAnswers: [],
  error: {},
};

const Invoices = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_QUESTIONS_SUCCESS:
      return {
        ...state,
        invoices: action.payload,
      };

    case GET_QUESTION_ANSWERS_SUCCESS:
      return {
        ...state,
        questionAnswers: action.payload,
      };

    case GET_QUESTIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_QUESTION_ANSWERS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_QUESTION_DETAIL_SUCCESS:
      return {
        ...state,
        invoiceDetail: action.payload,
      };

    case GET_QUESTION_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_QUESTION_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_ANSWER_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default Invoices;
