import React, { useEffect } from "react";
import { Card, CardBody, Col, Table } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import { getCompanyServices as onGetCompanyServices } from "store/actions";

function BusinessServices({ companyId }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onGetCompanyServices(companyId));
  }, [dispatch]);

  const { services } = useSelector((state) => state.projects);
  return (
    <Col xl={4}>
      <h5>Company Services</h5>
      <Card>
        <CardBody className="border-bottom">
          <div className="table-responsive">
            <Table className="table mb-0">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Service Name</th>
                </tr>
              </thead>
              <tbody>
                {services.map((service, idx) => (
                  <tr key={idx}>
                    <th scope="row">{idx + 1}</th>
                    <td>
                      <p>{service.sub_category.name}</p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
}

export default BusinessServices;
