import {
  GET_QUESTIONS,
  GET_QUESTIONS_FAIL,
  GET_QUESTIONS_SUCCESS,
  ADD_QUESTION,
  ADD_QUESTION_FAIL,
  ADD_QUESTION_SUCCESS,
  GET_QUESTION_DETAIL,
  GET_QUESTION_DETAIL_FAIL,
  GET_QUESTION_DETAIL_SUCCESS,
  GET_QUESTION_ANSWERS,
  GET_QUESTION_ANSWERS_FAIL,
  GET_QUESTION_ANSWERS_SUCCESS,
  ADD_QUESTION_ANSWER,
  ADD_QUESTION_ANSWER_FAIL,
  ADD_QUESTION_ANSWER_SUCCESS,
  DELETE_QUESTION,
  DELETE_QUESTION_FAIL,
  DELETE_ANSWER,
  DELETE_ANSWER_FAIL,
} from "./actionTypes";

export const getInvoices = () => ({
  type: GET_QUESTIONS,
});

export const getInvoicesSuccess = (invoices) => ({
  type: GET_QUESTIONS_SUCCESS,
  payload: invoices,
});

export const getInvoicesFail = (error) => ({
  type: GET_QUESTIONS_FAIL,
  payload: error,
});

export const addQuestion = (data) => ({
  type: ADD_QUESTION,
  payload: data,
});

export const addQuestionSuccess = (invoices) => ({
  type: ADD_QUESTION_SUCCESS,
  payload: invoices,
});

export const addQuestionFail = (error) => ({
  type: ADD_QUESTION_FAIL,
  payload: error,
});

export const deleteQuestion = (question_id) => ({
  type: DELETE_QUESTION,
  payload: question_id,
});

export const deleteQuestionFail = (error) => ({
  type: DELETE_QUESTION_FAIL,
  payload: error,
});

export const getInvoiceDetail = (invoiceId) => ({
  type: GET_QUESTION_DETAIL,
  invoiceId,
});

export const getInvoiceDetailSuccess = (invoices) => ({
  type: GET_QUESTION_DETAIL_SUCCESS,
  payload: invoices,
});

export const getInvoiceDetailFail = (error) => ({
  type: GET_QUESTION_DETAIL_FAIL,
  payload: error,
});

export const addQuestionAnswer = (data) => ({
  type: ADD_QUESTION_ANSWER,
  payload: data,
});

export const addQuestionAnswerSuccess = (answer) => ({
  type: ADD_QUESTION_ANSWER_SUCCESS,
  payload: answer,
});

export const addQuestionAnswerFail = (error) => ({
  type: ADD_QUESTION_ANSWER_FAIL,
  payload: error,
});

export const getQuestionAnswers = (question_id) => ({
  type: GET_QUESTION_ANSWERS,
  payload: question_id,
});

export const getQuestionAnswersSuccess = (questionAnswers) => ({
  type: GET_QUESTION_ANSWERS_SUCCESS,
  payload: questionAnswers,
});

export const getQuestionAnswersFail = (error) => ({
  type: GET_QUESTION_ANSWERS_FAIL,
  payload: error,
});

export const deleteAnswer = (answer_id) => ({
  type: DELETE_ANSWER,
  payload: answer_id,
});

export const deleteAnswerFail = (error) => ({
  type: DELETE_ANSWER_FAIL,
  payload: error,
});
