import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import {
  ADD_QUESTION,
  GET_QUESTIONS,
  GET_QUESTION_ANSWERS,
  ADD_QUESTION_ANSWER,
  DELETE_QUESTION,
  DELETE_ANSWER,
} from "./actionTypes";
import {
  getInvoicesSuccess,
  getInvoicesFail,
  addQuestionSuccess,
  addQuestionFail,
  getQuestionAnswersSuccess,
  getQuestionAnswersFail,
  addQuestionAnswerSuccess,
  addQuestionAnswerFail,
  deleteAnswerFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getInvoices,
  addQuestion,
  getQuestionAnswers,
  addQuestionAnswer,
  deleteQuestion,
  deleteQuestionAnswers,
} from "helpers/backend_helper";

function* fetchInvoices() {
  try {
    const response = yield call(getInvoices);
    yield put(getInvoicesSuccess(response.payload.questionList));
  } catch (error) {
    yield put(getInvoicesFail(error));
  }
}

function* onDeleteQuestion({ payload }) {
  try {
    yield call(deleteQuestion, payload);
    window.location.reload();
  } catch (error) {
    yield put(deleteQuestionFail(error));
  }
}

function* createQuestion({ payload }) {
  try {
    const response = yield call(addQuestion, payload);
    yield put(addQuestionSuccess(response.payload));
    window.location.reload();
  } catch (error) {
    yield put(addQuestionFail(error));
  }
}

function* createQuestionAnswer({ payload }) {
  try {
    const response = yield call(addQuestionAnswer, payload);
    yield put(addQuestionAnswerSuccess(response));
  } catch (error) {
    yield put(addQuestionAnswerFail(error));
  }
}

function* fetchQuestionAnswers({ payload }) {
  try {
    const response = yield call(getQuestionAnswers, payload);
    yield put(getQuestionAnswersSuccess(response.payload.answerListInfo));
  } catch (error) {
    yield put(getQuestionAnswersFail(error));
  }
}

function* onDeleteAnswer({ payload }) {
  try {
    yield call(deleteQuestionAnswers, payload);
    window.location.reload();
  } catch (error) {
    yield put(deleteAnswerFail(error));
  }
}

function* invoiceSaga() {
  yield takeEvery(GET_QUESTIONS, fetchInvoices);
  yield takeEvery(ADD_QUESTION, createQuestion);
  yield takeEvery(ADD_QUESTION_ANSWER, createQuestionAnswer);
  yield takeEvery(GET_QUESTION_ANSWERS, fetchQuestionAnswers);
  yield takeEvery(DELETE_QUESTION, onDeleteQuestion);
  yield takeEvery(DELETE_ANSWER, onDeleteAnswer);
}

export default invoiceSaga;
