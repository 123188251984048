import React, { useMemo, useState } from "react";
import { Card, CardBody, Col, Row, UncontrolledTooltip } from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import { JobTitle, Category } from "../ApplyJobs/ApplyJobsCol";
import TableContainer from "components/Common/TableContainer";
import DeleteModal from "components/Common/DeleteModal";
import { deleteSubCategories as onDeleteSubCategory } from "store/actions";

const JobData = ({ subCategories }) => {
  const dispatch = useDispatch();
  const [category, setCategory] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (category) => {
    setCategory(category);
    setDeleteModal(true);
  };

  const handleDeleteCategory = () => {
    if (category && category.id) {
      dispatch(onDeleteSubCategory(category.id));
      setDeleteModal(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        disableFilters: true,
        Cell: (cellProps) => {
          return <JobTitle {...cellProps} />;
        },
      },
      {
        Header: "Price (USD)",
        accessor: "listing_price",
        disableFilters: true,
        Cell: (cellProps) => {
          return <JobTitle {...cellProps} />;
        },
      },

      {
        Header: "Category",
        accessor: "category",
        disableFilters: true,
        Cell: (cellProps) => {
          return <Category {...cellProps} />;
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li>
                <Link
                  to={`/sub-category/${cellProps.row.original.id}`}
                  className="btn btn-sm btn-soft-primary"
                >
                  <i className="mdi mdi-eye-outline" id="viewtooltip" />
                  <UncontrolledTooltip placement="top" target="viewtooltip">
                    View
                  </UncontrolledTooltip>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={() => {
                    const categoryData = cellProps.row.original;
                    onClickDelete(categoryData);
                  }}
                >
                  <i className="mdi mdi-delete-outline" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCategory}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Row>
        <Col lg="12">
          <Card>
            <CardBody className="border-bottom">
              <div className="d-flex align-items-center">
                <h5 className="mb-0 card-title flex-grow-1">Sub categories</h5>
              </div>
            </CardBody>
            <CardBody>
              <TableContainer
                columns={columns}
                data={subCategories || []}
                isGlobalFilter={true}
                isAddUserList={false}
                customPageSize={10}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default JobData;
