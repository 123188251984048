import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import {
  GET_COMPANIES,
  GET_COMPANY_DETAIL,
  ADD_NEW_PROJECT,
  DELETE_PROJECT,
  UPDATE_PROJECT,
  GET_COMPANY_SERVICES,
  GET_COMPANY_LEADS,
} from "./actionTypes";
import {
  getProjectsSuccess,
  getProjectsFail,
  getProjectDetailSuccess,
  getProjectDetailFail,
  addProjectFail,
  addProjectSuccess,
  updateProjectSuccess,
  updateProjectFail,
  deleteProjectSuccess,
  deleteProjectFail,
  getCompanyServicesSuccess,
  getCompanyServicesFail,
  getCompanyLeadsSuccess,
  getCompanyLeadsFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getProjects,
  getProjectsDetails,
  addNewProject,
  updateProject,
  deleteProject,
  getCompanyServices,
  getCompanyLeads,
} from "helpers/backend_helper";

function* fetchProjects() {
  try {
    const response = yield call(getProjects);
    yield put(getProjectsSuccess(response.payload.businessInfo));
  } catch (error) {
    yield put(getProjectsFail(error));
  }
}

function* fetchProjectDetail({ projectId }) {
  try {
    const response = yield call(getProjectsDetails, projectId);
    yield put(getProjectDetailSuccess(response.payload.businessInfo[0]));
  } catch (error) {
    yield put(getProjectDetailFail(error));
  }
}

function* onUpdateProject({ payload: project }) {
  try {
    const response = yield call(updateProject, project);
    window.location.reload();
    yield put(updateProjectSuccess(response));
  } catch (error) {
    yield put(updateProjectFail(error));
  }
}

function* onDeleteProject({ payload: project }) {
  try {
    const response = yield call(deleteProject, project);
    window.location.reload();
    yield put(deleteProjectSuccess(response));
  } catch (error) {
    yield put(deleteProjectFail(error));
  }
}

function* onAddNewProject({ payload: project }) {
  try {
    const response = yield call(addNewProject, project);
    yield put(addProjectSuccess(response));
  } catch (error) {
    yield put(addProjectFail(error));
  }
}

function* onGetCompanyServices({ payload }) {
  try {
    const response = yield call(getCompanyServices, payload);
    yield put(getCompanyServicesSuccess(response.payload.businessServices));
  } catch (error) {
    yield put(getCompanyServicesFail(error));
  }
}

function* onGetCompanyLeads({ payload }) {
  try {
    const response = yield call(getCompanyLeads, payload);
    yield put(getCompanyLeadsSuccess(response.payload.businessLeadInfo));
  } catch (error) {
    yield put(getCompanyLeadsFail(error));
  }
}

function* projectsSaga() {
  yield takeEvery(GET_COMPANIES, fetchProjects);
  yield takeEvery(GET_COMPANY_DETAIL, fetchProjectDetail);
  yield takeEvery(ADD_NEW_PROJECT, onAddNewProject);
  yield takeEvery(UPDATE_PROJECT, onUpdateProject);
  yield takeEvery(DELETE_PROJECT, onDeleteProject);
  yield takeEvery(GET_COMPANY_SERVICES, onGetCompanyServices);
  yield takeEvery(GET_COMPANY_LEADS, onGetCompanyLeads);
}

export default projectsSaga;
