/* EVENTS */
export const GET_EVENTS = "GET_EVENTS";
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";
export const GET_EVENTS_FAIL = "GET_EVENTS_FAIL";

export const ADD_NEW_EVENT = "ADD_NEW_EVENT";
export const ADD_EVENT_SUCCESS = "ADD_EVENT_SUCCESS";
export const ADD_EVENT_FAIL = "ADD_EVENT_FAIL";

export const UPDATE_EVENT = "UPDATE_EVENT";
export const UPDATE_EVENT_SUCCESS = "UPDATE_EVENT_SUCCESS";
export const UPDATE_EVENT_FAIL = "UPDATE_EVENT_FAIL";

export const DELETE_EVENT = "DELETE_EVENT";
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";
export const DELETE_EVENT_FAIL = "DELETE_EVENT_FAIL";

/* CATEGORIES */
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_FAIL = "GET_CATEGORIES_FAIL";

export const ADD_NEW_CATEGORY = "ADD_NEW_CATEGORY";
export const ADD_CATEGORIES_SUCCESS = "ADD_CATEGORIES_SUCCESS";
export const ADD_CATEGORIES_FAIL = "ADD_CATEGORIES_FAIL";

export const UPDATE_CATEGORIES = "UPDATE_CATEGORIES";
export const UPDATE_CATEGORIES_SUCCESS = "UPDATE_CATEGORIES_SUCCESS";
export const UPDATE_CATEGORIES_FAIL = "UPDATE_CATEGORIES_FAIL";

export const DELETE_CATEGORIES = "DELETE_CATEGORIES";
export const DELETE_CATEGORIES_SUCCESS = "DELETE_CATEGORIES_SUCCESS";
export const DELETE_CATEGORIES_FAIL = "DELETE_CATEGORIES_FAIL";

export const ADD_SUB_CATEGORY = "ADD_SUB_CATEGORY";
export const ADD_SUB_CATEGORIES_SUCCESS = "ADD_SUB_CATEGORIES_SUCCESS";
export const ADD_SUB_CATEGORIES_FAIL = "ADD_SUB_CATEGORIES_FAIL";

export const GET_SUB_CATEGORY = "GET_SUB_CATEGORY";
export const GET_SUB_CATEGORIES_SUCCESS = "GET_SUB_CATEGORIES_SUCCESS";
export const GET_SUB_CATEGORIES_FAIL = "GET_SUB_CATEGORIES_FAIL";

export const GET_SUB_CATEGORY_DETAILS = "GET_SUB_CATEGORY_DETAILS";
export const GET_SUB_CATEGORIES_DETAILS_SUCCESS =
  "GET_SUB_CATEGORIES_DETAILS_SUCCESS";
export const GET_SUB_CATEGORIES_DETAILS_FAIL =
  "GET_SUB_CATEGORIES_DETAILS_FAIL";

export const GET_SUB_CATEGORY_QNS = "GET_SUB_CATEGORY_QNS";
export const GET_SUB_CATEGORIES_QNS_SUCCESS = "GET_SUB_CATEGORIES_QNS_SUCCESS";
export const GET_SUB_CATEGORIES_QNS_FAIL = "GET_SUB_CATEGORIES_DETAILS_FAIL";

export const DELETE_SUBCATEGORY = "DELETE_SUBCATEGORY";
export const DELETE_SUBCATEGORY_SUCCESS = "DELETE_SUBCATEGORY_SUCCESS";
export const DELETE_SUBCATEGORY_FAIL = "DELETE_SUBCATEGORY_FAIL";
