import { API_SUCCESS, API_FAIL, GET_STATISTICS_DATA } from "./actionType";

const INIT_STATE = {
  companies: 0,
  jobs: 0,
  leads: 0,
  users: 0,
  statistic_data: {
    companyStats: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    leadStats: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    jobStats: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    userStats: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    label: [
      `01/01/${new Date().getFullYear()}`,
      `02/01/${new Date().getFullYear()}`,
      `03/01/${new Date().getFullYear()}`,
      `04/01/${new Date().getFullYear()}`,
      `05/01/${new Date().getFullYear()}`,
      `06/01/${new Date().getFullYear()}`,
      `07/01/${new Date().getFullYear()}`,
      `08/01/${new Date().getFullYear()}`,
      `09/01/${new Date().getFullYear()}`,
      `10/01/${new Date().getFullYear()}`,
      `11/01/${new Date().getFullYear()}`,
      `12/01/${new Date().getFullYear()}`,
    ],
  },
};

const DashboardJob = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_SUCCESS:
      switch (action.payload.actionType) {
        case GET_STATISTICS_DATA:
          return {
            ...state,
            statistic_data: {
              label: state.statistic_data.label,
              companyStats: formatPayload(
                action.payload.data?.companyStatisticsInfo
              ),
              leadStats: formatPayload(action.payload.data?.leadStatisticsInfo),
              jobStats: formatPayload(action.payload.data?.jobStatisticsInfo),
              userStats: formatPayload(action.payload.data?.userStatisticsInfo),
            },
            companies: action.payload.data.companyCount,
            jobs: action.payload.data.jobsCount,
            leads: action.payload.data.leadsCount,
            users: action.payload.data.usersCount,
          };
        default:
          return state;
      }
    case API_FAIL:
      switch (action.payload.actionType) {
        case GET_STATISTICS_DATA:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return state;
      }
    default:
      return state;
  }
};

export default DashboardJob;

const formatPayload = (data) => {
  let stats = [];

  for (let i = 0; i < 12; i++) {
    let month_i = i + 1;
    let statVal = data.find((item) =>
      item.month === month_i ? item.count : 0
    );
    stats[i] = statVal ? statVal.count : 0;
  }
  return stats;
};
