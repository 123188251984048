import React, { useMemo, useEffect, useState } from "react";
import {
  Container,
  Col,
  Card,
  CardBody,
  UncontrolledTooltip,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  Input,
  Label,
  FormFeedback,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { map } from "lodash";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import TableContainer from "components/Common/TableContainer";
import DeleteModal from "components/Common/DeleteModal";
import { JobTitle, QuestionType } from "../ApplyJobs/ApplyJobsCol";

import {
  getSubCategoryDetails as onGetSubCategoryDetails,
  getSubCategoryQns as onGetSubCategoryQns,
  addQuestion as onAddQuestion,
  getQuestionAnswers as onGetQuestionAnswers,
  addQuestionAnswer as onAddQuestionAnswer,
  deleteQuestion as onDeleteQuestion,
  deleteAnswer as onDeleteAnswer,
} from "store/actions";
import { getAwsFile } from "helpers/aws";

const questionTypeOptions = [
  {
    value: 1,
    name: "Common",
  },
  {
    value: 2,
    name: "Business specifics",
  },
];

const inputTypeOptions = [
  {
    value: 1,
    name: "Text",
  },
  {
    value: 2,
    name: "Radio",
  },
  {
    value: 3,
    name: "Checkbox",
  },
  {
    value: 4,
    name: "Dropdown",
  },
  {
    value: 5,
    name: "Datepicker",
  },
];

const SubCategory = () => {
  //meta title
  document.title = "Sub category | Securlist - Dashboard";
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onGetSubCategoryDetails(id));
    dispatch(onGetSubCategoryQns(id));
  }, [dispatch]);

  const { subCategory, subCategoryQns } = useSelector(
    (state) => state.category
  );
  const { answers } = useSelector((state) => ({
    answers: state.invoices.questionAnswers,
  }));

  const [question, setQuestion] = useState(null);
  const [modal, setModal] = useState(false);
  const [modalAns, setModalAns] = useState(false);
  const [formData, setformData] = useState({
    question_type: 1,
    input_type: 1,
    question_order: 0,
  });

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (item) => {
    setQuestion(item);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (question && question.id) {
      dispatch(onDeleteQuestion(question.id));
    }
    setDeleteModal(false);
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      question_name: "",
    },
    validationSchema: Yup.object({
      question_name: Yup.string().required("Please Enter Question"),
    }),

    onSubmit: (values) => {
      const newQuestionList = {
        ...formData,
        question_name: values["question_name"],
        question_order: subCategoryQns.length + 1,
        added_by: JSON.parse(localStorage.getItem("authUser")).payload.id,
        sub_category_id: id,
      };
      // save new Job
      dispatch(onAddQuestion(newQuestionList));
      validation.resetForm();
      setformData({});
      toggle();
    },
  });

  useEffect(() => {}, [question]);

  const answerValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      answer_name: "",
    },
    validationSchema: Yup.object({
      answer_name: Yup.string().required("Please Enter option"),
    }),

    onSubmit: (values) => {
      const newQuestionList = {
        answer_name: values["answer_name"],
        question_id: question?.id,
        added_by: JSON.parse(localStorage.getItem("authUser")).payload.id,
        type: "dropdown",
      };
      // save new Job
      dispatch(onAddQuestionAnswer(newQuestionList));
      answerValidation.resetForm();
      setformData({});
      toggleAns();
    },
  });

  const toggle = () => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  };

  const toggleAns = () => {
    if (modalAns) {
      setModalAns(false);
    } else {
      setModalAns(true);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Order No.",
        accessor: "question_order",
        disableFilters: true,
        Cell: (cellProps) => {
          return <JobTitle {...cellProps} />;
        },
      },
      {
        Header: "Question",
        accessor: "question_name",
        disableFilters: true,
        Cell: (cellProps) => {
          return <JobTitle {...cellProps} />;
        },
      },
      {
        Header: "Question Type",
        accessor: "question_type",
        disableFilters: true,
        Cell: (cellProps) => {
          return <QuestionType {...cellProps} />;
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              {cellProps.row.original.input_type > 1 && (
                <li>
                  <Link
                    to="#"
                    className="btn btn-sm btn-soft-primary"
                    onClick={() => {
                      dispatch(onGetQuestionAnswers(cellProps.row.original.id));
                      setQuestion(cellProps.row.original);
                      toggleAns();
                    }}
                  >
                    <i
                      className="mdi mdi-archive-alert-outline"
                      id="qntooltip"
                    />
                    <UncontrolledTooltip placement="top" target="qntooltip">
                      Add Question Options
                    </UncontrolledTooltip>
                  </Link>
                </li>
              )}
              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={() => {
                    const categoryData = cellProps.row.original;
                    onClickDelete(categoryData);
                  }}
                >
                  <i className="mdi mdi-delete-outline" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={handleDeleteUser}
            onCloseClick={() => setDeleteModal(false)}
          />
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Sub category" breadcrumbItem="Details" />
          <Col xl={12}>
            <Card>
              <CardBody className="border-bottom">
                <div className="d-flex">
                  <img
                    src={getAwsFile(subCategory?.icon)}
                    alt="service"
                    height="100"
                  />
                  <div className="flex-grow-1 ms-3">
                    <h5 className="fw-semibold">{subCategory?.name}</h5>
                    <ul className="list-unstyled hstack gap-2 mb-0">
                      <li>
                        <span className="text-muted">
                          {`USD ${subCategory?.listing_price}`}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          {/*  */}
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="border-bottom">
                  <div className="d-flex align-items-center">
                    <h5 className="mb-0 card-title flex-grow-1">
                      Sub category Questions
                    </h5>
                  </div>
                </CardBody>

                <CardBody>
                  <Col lg="4">
                    <button onClick={toggle} className="btn btn-primary mb-3">
                      Add Question
                    </button>
                  </Col>
                  <TableContainer
                    columns={columns}
                    data={subCategoryQns || []}
                    isGlobalFilter={true}
                    isAddUserList={false}
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle} tag="h4">
            Add Sub Category Question
          </ModalHeader>
          <ModalBody>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Row>
                <Col className="col-12">
                  <div className="mb-3">
                    <Label className="form-label">Question</Label>
                    <Input
                      name="question_name"
                      type="text"
                      placeholder="Insert Question"
                      validate={{
                        required: { value: true },
                      }}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.question_name || ""}
                      invalid={
                        validation.touched.question_name &&
                        validation.errors.question_name
                          ? true
                          : false
                      }
                    />
                    {validation.touched.question_name &&
                    validation.errors.question_name ? (
                      <FormFeedback type="invalid">
                        {validation.errors.question_name}
                      </FormFeedback>
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <Label className="form-label">Select Question Type</Label>
                    <Input
                      name="question_type"
                      type="select"
                      className="form-select"
                      onChange={(event) => {
                        setformData({
                          ...formData,
                          question_type: event.target.value,
                        });
                      }}
                      onBlur={() => {}}
                    >
                      {questionTypeOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.name}
                        </option>
                      ))}
                    </Input>
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Select Input Type</Label>
                    <Input
                      name="input_type"
                      type="select"
                      onChange={(e) =>
                        setformData((formData) => ({
                          ...formData,
                          input_type: e.target.value,
                        }))
                      }
                      onBlur={() => {}}
                    >
                      {inputTypeOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.name}
                        </option>
                      ))}
                    </Input>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-end">
                    <button type="submit" className="btn btn-success save-user">
                      Save
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>

        {/* Add Answer Modal */}
        <Modal isOpen={modalAns} toggle={toggleAns}>
          <ModalHeader toggle={toggleAns} tag="h4">
            {question?.question_name}
          </ModalHeader>
          <ModalBody>
            <div className="table-responsive">
              <Table className="project-list-table table-nowrap align-middle table-borderless">
                <thead>
                  <tr>
                    <th scope="col">Options</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {map(answers, (answer, index) => (
                    <tr key={index}>
                      <td>
                        <p className="text-muted mb-0">{answer.answer_name}</p>
                      </td>

                      <td>
                        <UncontrolledDropdown>
                          <DropdownToggle
                            href="#"
                            className="card-drop"
                            tag="a"
                          >
                            <i className="mdi mdi-dots-horizontal font-size-18" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem
                              href="#"
                              onClick={() =>
                                dispatch(onDeleteAnswer(answer.id))
                              }
                            >
                              <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
                              Delete
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <h5 className="pb-3">Add Option</h5>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                answerValidation.handleSubmit();
                return false;
              }}
            >
              <Row>
                <Col className="col-12">
                  <div className="mb-3">
                    <Label className="form-label">Question Option</Label>
                    <Input
                      name="answer_name"
                      type="text"
                      placeholder="Insert Question Option"
                      validate={{
                        required: { value: true },
                      }}
                      onChange={answerValidation.handleChange}
                      onBlur={answerValidation.handleBlur}
                      value={answerValidation.values.answer_name || ""}
                      invalid={
                        answerValidation.touched.answer_name &&
                        answerValidation.errors.answer_name
                          ? true
                          : false
                      }
                    />
                    {answerValidation.touched.answer_name &&
                    answerValidation.errors.answer_name ? (
                      <FormFeedback type="invalid">
                        {answerValidation.errors.answer_name}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-end">
                    <button type="submit" className="btn btn-success save-user">
                      Save
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default SubCategory;
