import React from "react";
import { Card, CardBody, Col } from "reactstrap";

const AboutUs = ({ education }) => {
  return (
    <React.Fragment>
      <Col lg={6}>
        <Card>
          <CardBody>
            <h5 className="mb-3">Education</h5>
            <ul className="verti-timeline list-unstyled">
              {education.map((item) => (
                <li className="event-list" key={item.id}>
                  <div className="event-timeline-dot">
                    <i className="bx bx-right-arrow-circle"></i>
                  </div>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <div>
                        <h6 className="font-size-14 mb-1">{item.degree}</h6>
                        <p className="text-muted">{item.university}</p>
                        <p className="text-muted mb-0">{item.passing_year}</p>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default AboutUs;
