import axios from "axios";
import { post } from "helpers/api_helper";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Card,
  CardBody,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Toast,
  ToastHeader,
  ToastBody,
} from "reactstrap";
import {
  deleteCategories as onDeleteCategories,
  addSubCategory as onAddSubCategory,
} from "store/actions";

const List = ({ categories }) => {
  const dispatch = useDispatch();

  const [toast, setToast] = useState(false);
  const [formState, setFormState] = useState({});
  const [imageState, setImageState] = useState({ icon: null, iconPrev: null });
  const [category, setCategory] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const handleDelete = (id) => {
    dispatch(onDeleteCategories(id));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const toggleToast = () => {
    setToast(!toast);
  };

  const handleSubmit = async () => {
    const API_URL = process.env.REACT_APP_APIURL;
    const token = localStorage.getItem("TOKEN");
    const formData = new FormData();
    if (!imageState.icon) {
      toggleToast();
      toggle();
    } else {
      formData.append("category_id", category.id.toString());
      formData.append("listing_price", formState.listing_price);
      formData.append("icon", imageState.icon);
      formData.append("name", formState.name);
      //   dispatch(onAddSubCategory(formData));
      try {
        await axios.post(`${API_URL}common/subCategory`, formData, {
          headers: {
            "x-auth-token": token,
            "Content-Type": "multipart/form-data",
          },
        });
      } catch (error) {
        console.log(error.message);
      }
      setImageState({});
      toggle();
    }

    //   post(``, formData);
  };

  return (
    <React.Fragment>
      <div
        className="position-fixed top-0 end-0 p-3"
        style={{ zIndex: "1005" }}
      >
        <Toast isOpen={toast}>
          <ToastHeader toggle={toggleToast}>Warning</ToastHeader>
          <ToastBody>Please provide sub category image.</ToastBody>
        </Toast>
      </div>
      <Row>
        {categories.map((item, key) => (
          <Col xl={3} key={key}>
            <Card>
              <CardBody>
                <div className="d-flex align-start mb-3">
                  <div className="flex-grow-1" />
                  <button
                    type="button"
                    className="btn btn-danger btn-sm like-btn"
                    onClick={() => handleDelete(item.id)}
                  >
                    <i className="mdi mdi-delete"></i>
                  </button>
                </div>
                <div className="text-center mb-3">
                  <i
                    className="mdi mdi-security-network"
                    style={{ fontSize: 50 }}
                  ></i>
                  <h6 className="font-size-15 mt-3 mb-1">{item.name}</h6>
                </div>

                <div className="mt-4 pt-1">
                  <button
                    onClick={() => {
                      toggle();
                      setCategory(item);
                    }}
                    className="btn btn-soft-primary d-block w-100"
                  >
                    Add Sub-category
                  </button>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>

      {/* Modal */}
      <Modal isOpen={isOpen} toggle={toggle}>
        <div className="modal-content">
          <ModalHeader toggle={toggle} className="modal-header">
            Add {category?.name} Sub category
          </ModalHeader>
          <ModalBody>
            <Row>
              {imageState.iconPrev && (
                <Col lg={12}>
                  <div className="mb-3">
                    <h6>Image Preview</h6>
                    <img
                      src={imageState.iconPrev}
                      style={{ width: 80, height: 95, borderRadius: 5 }}
                    />
                  </div>
                </Col>
              )}
              <Col lg={12}>
                <div className="mb-3">
                  <Label htmlFor="fullnameInput" className="form-label">
                    Sub Category Name
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="fullnameInput"
                    name="name"
                    onChange={handleChange}
                    placeholder="Enter sub category name"
                  />
                </div>
              </Col>
              <Col lg={12}>
                <div className="mb-3">
                  <Label htmlFor="emailInput" className="form-label">
                    Leads Price (USD)
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="emailInput"
                    name="listing_price"
                    onChange={handleChange}
                    placeholder="Enter cost for a lead"
                  />
                </div>
              </Col>

              <Col lg={12}>
                <div className="mb-3">
                  <Label htmlFor="uploadResume" className="form-label">
                    Upload Sub category Image
                  </Label>
                  <Input
                    type="file"
                    className="form-control"
                    placeholder="Upload Image"
                    accept="image/*"
                    onChange={(e) =>
                      setImageState((imgState) => ({
                        ...imgState,
                        icon: e.target.files[0],
                        iconPrev: URL.createObjectURL(e.target.files[0]),
                      }))
                    }
                  />
                </div>
              </Col>

              <Col lg={12}>
                <div className="text-end">
                  <button
                    type="button"
                    onClick={handleSubmit}
                    className="btn btn-success me-1"
                  >
                    Submit
                  </button>
                </div>
              </Col>
            </Row>
          </ModalBody>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default List;
