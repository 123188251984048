import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { useParams } from "react-router-dom";
import { getApplyJobDetails as OnGetApplyJobDetails } from "store/jobs/actions";

//import images
import avatar from "../../../assets/images/users/avatar-6.jpg";
import AboutUs from "./AboutUs";
import Sidebar from "./Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { getAwsFile } from "helpers/aws";

const CandidateOverview = () => {
  document.title = "Candidate Overview | Securlist - Dashboard";

  const { id } = useParams();
  const dispatch = useDispatch();
  const { jobApply } = useSelector((state) => ({
    jobApply: state.JobReducer.jobSeeker,
  }));

  const [apply, setApply] = useState(null);

  useEffect(() => {
    dispatch(OnGetApplyJobDetails(id));
  }, [dispatch]);

  useEffect(() => {
    setApply(jobApply);
  }, [jobApply]);

  let education = apply?.education_info
    ? JSON.parse(apply?.education_info)
    : [];
  let experience = apply?.experience_info
    ? JSON.parse(apply?.experience_info)
    : [];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card className="mx-n4 mt-n4 bg-info bg-soft">
                <CardBody>
                  <div className="text-center mb-4">
                    <img
                      src={getAwsFile(apply?.user.user_profile)}
                      alt=""
                      className="avatar-md rounded-circle mx-auto d-block"
                    />
                    <h5 className="mt-3 mb-1">{apply?.user.user_name}</h5>
                    <p className="text-muted mb-3">{apply?.user.user_email}</p>
                  </div>
                  <div className="d-flex align-items-center">
                    {/* <ul className="list-unstyled hstack gap-3 mb-0 flex-grow-1">
                      {service.map((item) => (
                        <li key={item.id}>
                          <i className="bx bx-time align-middle"></i>{" "}
                          {item.name}
                        </li>
                      ))}
                    </ul> */}
                    <div className="hstack gap-2">
                      {apply?.resume != "undefined" ? (
                        <a
                          href={getAwsFile(apply?.resume)}
                          download="Resume"
                          className="btn btn-primary"
                        >
                          Download{" "}
                          <i className="bx bx-download align-baseline ms-1"></i>
                        </a>
                      ) : null}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Sidebar experience={experience} />
            <AboutUs education={education} />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CandidateOverview;
