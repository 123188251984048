import * as S3 from "aws-sdk/clients/s3";

export const awsBucketName = process.env.REACT_APP_AWS_BUCKET_NAME;

export const s3 = new S3({
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

export const getAwsFile = (key) => {
  // const fileParams = {
  //   Bucket: awsBucketName,
  //   Key: key,
  // };

  // return s3.getSignedUrl('getObject', fileParams);
  return !key?.length
    ? ""
    : `https://${awsBucketName}.s3.amazonaws.com/${key.replace(
        /^\/|\/$/g,
        ""
      )}`;
};

export default s3;
