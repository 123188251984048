/* INVOICES */
export const GET_QUESTIONS = "GET_QUESTIONS";
export const GET_QUESTIONS_SUCCESS = "GET_QUESTIONS_SUCCESS";
export const GET_QUESTIONS_FAIL = "GET_QUESTIONS_FAIL";

export const ADD_QUESTION = "ADD_QUESTION";
export const ADD_QUESTION_SUCCESS = "ADD_QUESTION_SUCCESS";
export const ADD_QUESTION_FAIL = "ADD_QUESTION_FAIL";

export const DELETE_QUESTION = "DELETE_QUESTION";
export const DELETE_QUESTION_FAIL = "DELETE_QUESTION_FAIL";

export const GET_QUESTION_DETAIL = "GET_QUESTION_DETAIL";
export const GET_QUESTION_DETAIL_SUCCESS = "GET_QUESTION_DETAIL_SUCCESS";
export const GET_QUESTION_DETAIL_FAIL = "GET_QUESTION_DETAIL_FAIL";

export const GET_QUESTION_ANSWERS = "GET_QUESTION_ANSWERS";
export const GET_QUESTION_ANSWERS_SUCCESS = "GET_QUESTION_ANSWERS_SUCCESS";
export const GET_QUESTION_ANSWERS_FAIL = "GET_QUESTION_ANSWERS_FAIL";

export const ADD_QUESTION_ANSWER = "ADD_QUESTION_ANSWER";
export const ADD_QUESTION_ANSWER_SUCCESS = "ADD_QUESTION_ANSWER_SUCCESS";
export const ADD_QUESTION_ANSWER_FAIL = "ADD_QUESTION_ANSWER_FAIL";

export const DELETE_ANSWER = "DELETE_ANSWER";
export const DELETE_ANSWER_FAIL = "DELETE_ANSWER_FAIL";
