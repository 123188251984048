/* USERS */
export const GET_USERS = "GET_USERS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAIL = "GET_USERS_FAIL";

/* USERS PROFILE */
export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
export const GET_USER_PROFILE_FAIL = "GET_USER_PROFILE_FAIL";

/**
 * add user
 */
export const GET_USER_REQUEST_INFO = "GET_USER_REQUEST_INFO";
export const GET_USER_REQUEST_INFO_SUCCESS = "GET_USER_REQUEST_INFO_SUCCESS";
export const GET_USER_REQUEST_INFO_FAIL = "GET_USER_REQUEST_INFO_FAIL";

/**
 * Edit user
 */
export const GET_USER_RESUME = "GET_USER_RESUME";
export const GET_USER_RESUME_SUCCESS = "GET_USER_RESUME_SUCCESS";
export const GET_USER_RESUME_FAIL = "GET_USER_RESUME_FAIL";

/**
 * Delete user
 */
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";
