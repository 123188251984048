/* PROJECTS */
export const GET_COMPANIES = "GET_COMPANIES";
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";
export const GET_COMPANIES_FAIL = "GET_COMPANIES_FAIL";

/* PROJECTS DETAIL*/
export const GET_COMPANY_DETAIL = "GET_COMPANY_DETAIL";
export const GET_COMPANY_DETAIL_SUCCESS = "GET_COMPANY_DETAIL_SUCCESS";
export const GET_COMPANY_DETAIL_FAIL = "GET_COMPANY_DETAIL_FAIL";

export const GET_COMPANY_SERVICES = "GET_COMPANY_SERVICES";
export const GET_COMPANY_SERVICES_SUCCESS = "GET_COMPANY_SERVICES_SUCCESS";
export const GET_COMPANY_SERVICES_FAIL = "GET_COMPANY_SERVICES_FAIL";

export const GET_COMPANY_LEADS = "GET_COMPANY_LEADS";
export const GET_COMPANY_LEADS_SUCCESS = "GET_COMPANY_LEADS_SUCCESS";
export const GET_COMPANY_LEADS_FAIL = "GET_COMPANY_LEADS_FAIL";

/**
 * add user
 */
export const ADD_NEW_PROJECT = "ADD_NEW_PROJECT";
export const ADD_PROJECT_SUCCESS = "ADD_PROJECT_SUCCESS";
export const ADD_PROJECT_FAIL = "ADD_PROJECT_FAIL";

/**
 * Edit user
 */
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";
export const UPDATE_PROJECT_FAIL = "UPDATE_PROJECT_FAIL";

/**
 * Delete user
 */
export const DELETE_PROJECT = "DELETE_PROJECT";
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS";
export const DELETE_PROJECT_FAIL = "DELETE_PROJECT_FAIL";
