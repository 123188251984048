import { takeEvery, put, call } from "redux-saga/effects";

// Calender Redux States
import {
  ADD_NEW_CATEGORY,
  DELETE_CATEGORIES,
  GET_CATEGORIES,
  UPDATE_CATEGORIES,
  ADD_SUB_CATEGORY,
  GET_SUB_CATEGORY,
  DELETE_SUBCATEGORY,
  GET_SUB_CATEGORY_DETAILS,
  GET_SUB_CATEGORY_QNS,
} from "./actionTypes";
import {
  getCategoriesSuccess,
  getCategoriesFail,
  addCategoriesFail,
  addCategoriesSuccess,
  updateCategoriesSuccess,
  updateCategoriesFail,
  deleteCategoriesSuccess,
  deleteCategoriesFail,
  addSubCategorySuccess,
  addSubCategoriesFail,
  getSubCategorySuccess,
  getSubCategoriesFail,
  deleteSubCategoriesFail,
  deleteSubCategoriesSuccess,
  getSubCategoryDetailsSuccess,
  getSubCategoryDetailsFail,
  getSubCategoryQnsSuccess,
  getSubCategoryQnsFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  addNewCategorie,
  updateCategories,
  deleteCategories,
  getCategories,
  getSubCategories,
  addSubCategory,
  deleteSubCategory,
  getSubCategoryDetails,
  getSubCategoryQns,
} from "../../helpers/backend_helper";

function* fetchCategories() {
  try {
    const response = yield call(getCategories);
    yield put(getCategoriesSuccess(response.payload.category));
  } catch (error) {
    yield put(getCategoriesFail(error));
  }
}

function* onAddNewCategorie({ payload: categorie }) {
  try {
    const response = yield call(addNewCategorie, categorie);
    window.location.reload();
    yield put(addCategoriesSuccess(response));
  } catch (error) {
    yield put(addCategoriesFail(error));
  }
}

function* onUpdateCategorie({ payload: categorie }) {
  try {
    const response = yield call(updateCategories, categorie);
    yield put(updateCategoriesSuccess(response));
  } catch (error) {
    yield put(updateCategoriesFail(error));
  }
}

function* onDeleteCategorie({ payload: categorie }) {
  try {
    const response = yield call(deleteCategories, categorie);
    window.location.reload();
    yield put(deleteCategoriesSuccess(response));
  } catch (error) {
    yield put(deleteCategoriesFail(error));
  }
}

function* onAddSubCategories({ payload }) {
  try {
    const response = yield call(addSubCategory, payload);
    yield put(addSubCategorySuccess(response));
  } catch (error) {
    yield put(addSubCategoriesFail(error));
  }
}

function* fetchSubCategories() {
  try {
    const response = yield call(getSubCategories);
    yield put(getSubCategorySuccess(response.payload.subCategory));
  } catch (error) {
    yield put(getSubCategoriesFail(error));
  }
}

function* fetchSubCategoryDetails({ payload }) {
  try {
    const response = yield call(getSubCategoryDetails, payload);
    yield put(getSubCategoryDetailsSuccess(response.payload.subCategory[0]));
  } catch (error) {
    yield put(getSubCategoryDetailsFail(error));
  }
}

function* fetchSubCategoryQuestion({ payload }) {
  try {
    const response = yield call(getSubCategoryQns, payload);
    yield put(getSubCategoryQnsSuccess(response.payload.questionList));
  } catch (error) {
    yield put(getSubCategoryQnsFail(error));
  }
}

function* onDeleteSubCategory({ payload: category }) {
  try {
    const response = yield call(deleteSubCategory, category);
    window.location.reload();
    yield put(deleteSubCategoriesSuccess(response));
  } catch (error) {
    yield put(deleteSubCategoriesFail(error));
  }
}

function* calendarSaga() {
  yield takeEvery(GET_CATEGORIES, fetchCategories);
  yield takeEvery(ADD_NEW_CATEGORY, onAddNewCategorie);
  yield takeEvery(UPDATE_CATEGORIES, onUpdateCategorie);
  yield takeEvery(DELETE_CATEGORIES, onDeleteCategorie);
  yield takeEvery(ADD_SUB_CATEGORY, onAddSubCategories);
  yield takeEvery(GET_SUB_CATEGORY, fetchSubCategories);
  yield takeEvery(DELETE_SUBCATEGORY, onDeleteSubCategory);
  yield takeEvery(GET_SUB_CATEGORY_DETAILS, fetchSubCategoryDetails);
  yield takeEvery(GET_SUB_CATEGORY_QNS, fetchSubCategoryQuestion);
}

export default calendarSaga;
