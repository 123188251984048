import {
  GET_JOB_LIST,
  GET_JOB_LIST_FAIL,
  GET_JOB_LIST_SUCCESS,
  ADD_NEW_JOB_LIST,
  ADD_JOB_LIST_SUCCESS,
  ADD_JOB_LIST_FAIL,
  GET_JOB_DETAILS,
  GET_JOB_DETAILS_SUCCESS,
  GET_JOB_DETAILS_FAIL,
  DELETE_JOB_LIST,
  DELETE_JOB_LIST_SUCCESS,
  DELETE_JOB_LIST_FAIL,
  GET_APPLY_JOB,
  GET_APPLY_JOB_SUCCESS,
  GET_APPLY_JOB_FAIL,
  DELETE_APPLY_JOB,
  DELETE_APPLY_JOB_SUCCESS,
  DELETE_APPLY_JOB_FAIL,
  GET_APPLY_JOB_DETAILS,
  GET_APPLY_JOB_DETAILS_SUCCESS,
} from "./actionTypes";
//
export const getJobList = () => ({
  type: GET_JOB_LIST,
});

export const getJobListSuccess = (jobs) => ({
  type: GET_JOB_LIST_SUCCESS,
  payload: jobs,
});

export const getJobListFail = (error) => ({
  type: GET_JOB_LIST_FAIL,
  payload: error,
});

export const addNewJobList = (data) => ({
  type: ADD_NEW_JOB_LIST,
  payload: data,
});

export const addJobListSuccess = (job) => ({
  type: ADD_JOB_LIST_SUCCESS,
  payload: job,
});

export const addJobListFail = (error) => ({
  type: ADD_JOB_LIST_FAIL,
  payload: error,
});

export const getJobDetails = (job) => ({
  type: GET_JOB_DETAILS,
  payload: job,
});

export const getJobDetailsSuccess = (job) => ({
  type: GET_JOB_DETAILS_SUCCESS,
  payload: job,
});

export const getJobDetailsFail = (error) => ({
  type: GET_JOB_DETAILS_FAIL,
  payload: error,
});

export const deleteJobList = (data) => ({
  type: DELETE_JOB_LIST,
  payload: data,
});

export const deleteJobListSuccess = (data) => ({
  type: DELETE_JOB_LIST_SUCCESS,
  payload: data,
});

export const deleteJobListFail = (error) => ({
  type: DELETE_JOB_LIST_FAIL,
  payload: error,
});

export const getApplyJob = () => ({
  type: GET_APPLY_JOB,
});

export const getApplyJobSuccess = (data) => ({
  type: GET_APPLY_JOB_SUCCESS,
  payload: data,
});

export const getApplyJobFail = (error) => ({
  type: GET_APPLY_JOB_FAIL,
  payload: error,
});

export const getApplyJobDetails = (seekerId) => ({
  type: GET_APPLY_JOB_DETAILS,
  payload: seekerId,
});

export const getApplyJobDetailsSuccess = (data) => ({
  type: GET_APPLY_JOB_DETAILS_SUCCESS,
  payload: data,
});

export const getApplyJobDetailsFail = (error) => ({
  type: GET_APPLY_JOB_FAIL,
  payload: error,
});

export const deleteApplyJob = (data) => ({
  type: DELETE_APPLY_JOB,
  payload: data,
});

export const deleteApplyJobSuccess = (data) => ({
  type: DELETE_APPLY_JOB_SUCCESS,
  payload: data,
});

export const deleteApplyJobFail = (error) => ({
  type: DELETE_APPLY_JOB_FAIL,
  payload: error,
});
