import React, { useEffect } from "react";
import { Container, Row } from "reactstrap";
import ActivityFeed from "./ActivityFeed";
import AddedJobs from "./AddedJobs";
import CandidateSection from "./CandidateSection";

//Import Components
import ChartSection from "./ChartSection";

import Section from "./Section";
import StatisticsApplications from "./StatisticsApplications";
import { useSelector } from "react-redux";

const DashboardJob = () => {
  document.title = "Admin Dashboard";

  const { statistic_data, companies, jobs, leads, users } = useSelector(
    (state) => state.DashboardJob
  );

  useEffect(() => {}, [statistic_data, companies, jobs, leads, users]);

  const chartsData = [
    {
      id: 1,
      title: "Total Companies",
      price: companies,
      perstangeValue: "18.89",
      bagdeColor: "warning",
      seriesData: [
        {
          name: "Job View",
          data: statistic_data["companyStats"],
        },
      ],
      color: '["--bs-warning", "--bs-transparent"]',
    },
    {
      id: 2,
      statName: "jobStats",
      title: "Total Jobs",
      price: jobs,
      perstangeValue: "24.07",
      bagdeColor: "warning",
      seriesData: [
        {
          name: "New Application",
          data: statistic_data["jobStats"],
        },
      ],
      color: '["--bs-warning", "--bs-transparent"]',
    },
    {
      id: 3,
      statName: "leadStats",
      title: "Total Leads",
      price: leads,
      perstangeValue: " 8.41",
      bagdeColor: "warning",
      seriesData: [
        {
          name: "Total Approved",
          data: statistic_data["leadStats"],
        },
      ],
      color: '["--bs-warning", "--bs-transparent"]',
    },
    {
      id: 4,
      statName: "userStats",
      title: "Total Users",
      price: users,
      perstangeValue: " 20.63",
      // bagdeColor: "danger",
      bagdeColor: "warning",
      istrendingArrow: true,
      seriesData: [
        {
          name: "Total Rejected",
          data: statistic_data["userStats"],
        },
      ],
      color: '["--bs-warning", "--bs-transparent"]',
      // color: '["--bs-danger", "--bs-transparent"]',
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Section />

          <ChartSection chartsData={chartsData} />
          <Row>
            {/* StatisticsApplications */}
            <StatisticsApplications />

            {/* CandidateSection */}
            {/* <CandidateSection /> */}
          </Row>

          {/* <Row>
            <JobVacancy />
          </Row> */}

          {/* <Row>
            <ReceivedTime />
            <ActivityFeed />
            <AddedJobs />
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardJob;
