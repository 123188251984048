import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";

import { Value } from "./contactlistCol";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import {
  getUsers as onGetUsers,
  getUserRequestInfo as onGetUserRequestInfo,
  getUserResume as onGetUserResume,
  deleteUser as onDeleteUser,
} from "store/contacts/actions";
import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";
import { getAwsFile } from "helpers/aws";

const ContactsList = (props) => {
  //meta title
  document.title = "Securlist Dashboard Template";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();

  const { users } = useSelector((state) => ({
    users: state.contacts.users,
  }));

  const [modal, setModal] = useState(false);

  const columns = useMemo(() => {
    return [
      {
        Header: "Img",
        accessor: "user_profile",
        disableFilters: true,
        accessor: (cellProps) => (
          <>
            <div>
              <img
                className="rounded-circle avatar-xs"
                src={getAwsFile(cellProps.user_profile)}
                alt=""
              />
            </div>
          </>
        ),
      },
      {
        Header: "Name",
        accessor: "user_name",
        disableFilters: true,
        Cell: (cellProps) => {
          return <Value {...cellProps} />;
        },
      },
      {
        Header: "Email",
        accessor: "user_email",
        disableFilters: true,
        Cell: (cellProps) => {
          return <Value {...cellProps} />;
        },
      },
      {
        Header: "Contact",
        accessor: "user_phone",
        disableFilters: true,
        Cell: (cellProps) => {
          return <Value {...cellProps} />;
        },
      },
      {
        Header: "Address",
        accessor: "user_address",
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <>
              {" "}
              <Value {...cellProps} />{" "}
            </>
          );
        },
      },
      {
        Header: "Action",
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to={`/contacts-profile/${cellProps.row.original.id}`}
                className="text-success"
              >
                <i className="mdi mdi-eye font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  View
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ];
  }, []);

  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetUsers());
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users)) {
      setContact(users);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleUserClick = (arg) => {
    const user = arg;

    setContact({
      id: user.id,
      name: user.name,
      designation: user.designation,
      email: user.email,
      tags: user.tags,
      projects: user.projects,
    });

    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (users) => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.id) {
      dispatch(onDeleteUser(contact.id));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };

  const keyField = "id";

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Contacts" breadcrumbItem="User List" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={users}
                    isGlobalFilter={true}
                    isAddUserList={true}
                    handleUserClick={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
