import {
  GET_COMPANIES_FAIL,
  GET_COMPANIES_SUCCESS,
  GET_COMPANY_DETAIL_FAIL,
  GET_COMPANY_DETAIL_SUCCESS,
  ADD_PROJECT_SUCCESS,
  ADD_PROJECT_FAIL,
  UPDATE_PROJECT_FAIL,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAIL,
  GET_COMPANY_SERVICES_SUCCESS,
  GET_COMPANY_SERVICES_FAIL,
  GET_COMPANY_LEADS_SUCCESS,
  GET_COMPANY_LEADS_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  projects: [],
  projectDetail: {},
  services: [],
  leads: [],
  error: {},
};

const projects = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMPANIES_SUCCESS:
      return {
        ...state,
        projects: action.payload,
      };

    case GET_COMPANIES_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_COMPANY_SERVICES_SUCCESS:
      return {
        ...state,
        services: action.payload,
      };

    case GET_COMPANY_SERVICES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_COMPANY_LEADS_SUCCESS:
      return {
        ...state,
        leads: action.payload,
      };

    case GET_COMPANY_LEADS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case ADD_PROJECT_SUCCESS:
      return {
        ...state,
        projects: [...state.projects, action.payload],
      };

    case ADD_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_COMPANY_DETAIL_SUCCESS:
      return {
        ...state,
        projectDetail: action.payload,
      };

    case UPDATE_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        projects: state.projects.filter(
          (project) => project.id.toString() !== action.payload.toString()
        ),
      };

    case DELETE_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_COMPANY_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default projects;
