import {
  ADD_NEW_CATEGORY,
  ADD_CATEGORIES_SUCCESS,
  ADD_CATEGORIES_FAIL,
  UPDATE_CATEGORIES,
  UPDATE_CATEGORIES_SUCCESS,
  UPDATE_CATEGORIES_FAIL,
  DELETE_CATEGORIES,
  DELETE_CATEGORIES_SUCCESS,
  DELETE_CATEGORIES_FAIL,
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,
  ADD_SUB_CATEGORY,
  ADD_SUB_CATEGORIES_SUCCESS,
  ADD_SUB_CATEGORIES_FAIL,
  GET_SUB_CATEGORY,
  GET_SUB_CATEGORIES_SUCCESS,
  GET_SUB_CATEGORIES_FAIL,
  GET_SUB_CATEGORY_DETAILS,
  GET_SUB_CATEGORIES_DETAILS_SUCCESS,
  GET_SUB_CATEGORIES_DETAILS_FAIL,
  GET_SUB_CATEGORY_QNS,
  GET_SUB_CATEGORIES_QNS_SUCCESS,
  GET_SUB_CATEGORIES_QNS_FAIL,
  DELETE_SUBCATEGORY,
  DELETE_SUBCATEGORY_SUCCESS,
  DELETE_SUBCATEGORY_FAIL,
} from "./actionTypes";

export const getCategories = () => ({
  type: GET_CATEGORIES,
});

export const getCategoriesSuccess = (categories) => ({
  type: GET_CATEGORIES_SUCCESS,
  payload: categories,
});

export const getCategoriessFail = (error) => ({
  type: GET_CATEGORIES_FAIL,
  payload: error,
});

export const addNewCategories = (categories) => ({
  type: ADD_NEW_CATEGORY,
  payload: categories,
});

export const addCategoriesSuccess = (categories) => ({
  type: ADD_CATEGORIES_SUCCESS,
  payload: categories,
});

export const addCategoriesFail = (error) => ({
  type: ADD_CATEGORIES_FAIL,
  payload: error,
});

export const updateCategories = (categories) => ({
  type: UPDATE_CATEGORIES,
  payload: categories,
});

export const updateCategoriesSuccess = (categories) => ({
  type: UPDATE_CATEGORIES_SUCCESS,
  payload: categories,
});

export const updateCategoriesFail = (error) => ({
  type: UPDATE_CATEGORIES_FAIL,
  payload: error,
});

export const deleteCategories = (categories) => ({
  type: DELETE_CATEGORIES,
  payload: categories,
});

export const deleteCategoriesSuccess = (categories) => ({
  type: DELETE_CATEGORIES_SUCCESS,
  payload: categories,
});

export const deleteCategoriesFail = (error) => ({
  type: DELETE_CATEGORIES_FAIL,
  payload: error,
});

export const getCategoriesFail = (error) => ({
  type: GET_CATEGORIES_FAIL,
  payload: error,
});

export const addSubCategoriesFail = (error) => ({
  type: ADD_SUB_CATEGORIES_FAIL,
  payload: error,
});

export const addSubCategory = (sub_category) => ({
  type: ADD_SUB_CATEGORY,
  payload: sub_category,
});

export const addSubCategorySuccess = (sub_category) => ({
  type: ADD_SUB_CATEGORIES_SUCCESS,
  payload: sub_category,
});

export const getSubCategoriesFail = (error) => ({
  type: GET_SUB_CATEGORIES_FAIL,
  payload: error,
});

export const getSubCategory = () => ({
  type: GET_SUB_CATEGORY,
});

export const getSubCategorySuccess = (sub_category) => ({
  type: GET_SUB_CATEGORIES_SUCCESS,
  payload: sub_category,
});

export const getSubCategoryDetailsFail = (error) => ({
  type: GET_SUB_CATEGORIES_DETAILS_FAIL,
  payload: error,
});

export const getSubCategoryDetails = (sub_category) => ({
  type: GET_SUB_CATEGORY_DETAILS,
  payload: sub_category,
});

export const getSubCategoryDetailsSuccess = (sub_category) => ({
  type: GET_SUB_CATEGORIES_DETAILS_SUCCESS,
  payload: sub_category,
});

export const getSubCategoryQnsFail = (error) => ({
  type: GET_SUB_CATEGORIES_QNS_FAIL,
  payload: error,
});

export const getSubCategoryQns = (sub_category) => ({
  type: GET_SUB_CATEGORY_QNS,
  payload: sub_category,
});

export const getSubCategoryQnsSuccess = (sub_category) => ({
  type: GET_SUB_CATEGORIES_QNS_SUCCESS,
  payload: sub_category,
});

export const deleteSubCategories = (categories) => ({
  type: DELETE_SUBCATEGORY,
  payload: categories,
});

export const deleteSubCategoriesSuccess = (categories) => ({
  type: DELETE_SUBCATEGORY_SUCCESS,
  payload: categories,
});

export const deleteSubCategoriesFail = (error) => ({
  type: DELETE_SUBCATEGORY_FAIL,
  payload: error,
});
