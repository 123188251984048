import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { map } from "lodash";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";

// TableContainer

import { Pdate, Ddate, Name, Budget } from "./CryptoCol";

import TableContainer from "../../../components/Common/TableContainer";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//Import mini card widgets
import MiniCards from "./mini-card";

// import charts
import {
  getUserProfile,
  getUserRequestInfo,
  getUserResume,
} from "store/actions";
import { getAwsFile } from "helpers/aws";

const getCount = (data, param) =>
  param === "hired"
    ? data?.filter((item) => item.hired_by).length
    : data?.filter((item) => !item.hired_by).length;

const ContactsProfile = (props) => {
  //meta title
  document.title = "Securlist Dashboard Template";
  const { id } = useParams();
  const {
    userProfile,
    onGetUserProfile,
    onGetUserRequestInfo,
    userRequestInfo,
    onGetUserResume,
    userResumeInfo,
  } = props;
  // eslint-disable-next-line no-unused-vars
  const miniCards = [
    {
      title: "Request Count",
      iconClass: "bx-package",
      text: userRequestInfo?.length,
    },
    {
      title: "Hired Count",
      iconClass: "bx-check-circle",
      text: getCount(userRequestInfo, "hired"),
    },
    {
      title: "Pending Count",
      iconClass: "bx-hourglass",
      text: getCount(userRequestInfo, "pending"),
    },
  ];

  useEffect(() => {
    onGetUserProfile(id);
  }, [onGetUserProfile]);

  useEffect(() => {
    onGetUserRequestInfo(id);
  }, [onGetUserRequestInfo]);

  useEffect(() => {
    onGetUserResume(id);
  }, [onGetUserResume]);

  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "createdAt",
        disableFilters: true,
        filterable: true,
        Cell: (cellProps) => {
          return <Pdate {...cellProps} />;
        },
      },
      {
        Header: "Business Name",
        accessor: "business_type",
        disableFilters: true,
        filterable: true,
        Cell: (cellProps) => {
          return <Name {...cellProps} />;
        },
      },

      {
        Header: "Got Hired",
        accessor: "hired_by",
        disableFilters: true,
        filterable: true,
        Cell: (cellProps) => {
          return <Ddate {...cellProps} />;
        },
      },
      {
        Header: "Hired By",
        accessor: "business",
        disableFilters: true,
        filterable: true,
        Cell: (cellProps) => {
          return <Budget {...cellProps} />;
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Contacts" breadcrumbItem="Profile" />

          <Row>
            <Col xl="4">
              <Card>
                <div className="avatar-md ">
                  <img
                    src={getAwsFile(userProfile.user_profile)}
                    alt="user"
                    className="img-thumbnail rounded-circle"
                  />
                </div>
                <CardBody>
                  <CardTitle className="mb-4">Personal Information</CardTitle>

                  <div className="table-responsive">
                    <Table className="table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <th scope="row">Full Name :</th>
                          <td>{userProfile.user_name}</td>
                        </tr>
                        <tr>
                          <th scope="row">Mobile :</th>
                          <td>{userProfile.user_phone}</td>
                        </tr>
                        <tr>
                          <th scope="row">E-mail :</th>
                          <td>{userProfile.user_email}</td>
                        </tr>
                        <tr>
                          <th scope="row">Location :</th>
                          <td>{userProfile.user_address}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <CardTitle className="mb-5">Resume</CardTitle>
                  <div>
                    <ul className="verti-timeline list-unstyled">
                      {map(userResumeInfo, (experience, i) => (
                        <li
                          className={
                            experience.id === 1
                              ? "event-list active"
                              : "event-list"
                          }
                          key={"_exp_" + i}
                        >
                          <div className="event-timeline-dot">
                            <i
                              className={
                                experience.id === 1
                                  ? "bx bx-right-arrow-circle bx-fade-right"
                                  : "bx bx-right-arrow-circle"
                              }
                            />
                          </div>
                          <div className="d-flex">
                            <div className="me-3">
                              <i
                                className={
                                  "bx " +
                                  experience.iconClass +
                                  "h4 text-primary"
                                }
                              />
                            </div>
                            <div className="flex-grow-1">
                              <div>
                                <h5 className="font-size-15">
                                  Current Job: {experience.current_job_title}
                                </h5>
                                <p className="text-dark">
                                  Work Experience: {experience.expriance}
                                </p>
                                <span className="text-primary">
                                  Education: {experience.education}
                                </span>
                                <div>
                                  <a
                                    href={getAwsFile(experience.resume)}
                                    download="Resume"
                                    className="btn btn-primary"
                                  >
                                    Download{" "}
                                    <i className="bx bx-download align-baseline ms-1"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl="8">
              <Row>
                {map(miniCards, (card, key) => (
                  <MiniCards
                    title={card.title}
                    text={card.text}
                    iconClass={card.iconClass}
                    key={"_card_" + key}
                  />
                ))}
              </Row>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">User Requests</CardTitle>

                  <TableContainer
                    columns={columns}
                    data={userRequestInfo || []}
                    isGlobalFilter={false}
                    customPageSize={5}
                    customPageSizeOptions={true}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ContactsProfile.propTypes = {
  userProfile: PropTypes.any,
  userResumeInfo: PropTypes.any,
  userRequestInfo: PropTypes.any,
  onGetUserProfile: PropTypes.func,
  onGetUserRequestInfo: PropTypes.func,
  onGetUserResume: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
  userRequestInfo: contacts.userRequestInfo,
  userResumeInfo: contacts.userResumeInfo,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUserProfile: (id) => dispatch(getUserProfile(id)),
  onGetUserRequestInfo: (id) => dispatch(getUserRequestInfo(id)),
  onGetUserResume: (id) => dispatch(getUserResume(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ContactsProfile));
